import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Slack × AppSheet × GAS を利用した ChatOps なアプリ開発 〜テクノロジーと創意工夫で従業員のパフォーマンスの最大化を図る〜",
  "date": "2022-12-23T06:38:18.000Z",
  "slug": "entry/2022/12/23/153818",
  "tags": ["medley"],
  "hero": "./2022_12_23.png",
  "heroAlt": "Title"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`こんにちは。コーポレートデザイン部でコーポレート IT を担当している清水です。`}</p>
    <p>{`コーポレート IT では全従業員が利用するネットワークインフラや SaaS といった IT 全般を統括しており、社内で発生している課題について IT を駆使して解決しています。`}</p>
    <p>{`今回は、こうした課題解決の一例として、「`}<strong parentName="p">{`呼び鈴アプリ`}</strong>{`」の開発について背景と実装のご紹介をし、最後に`}<a parentName="p" {...{
        "href": "#about"
      }}>{`コーポレート IT チーム`}</a>{`の業務内容や理念についてご紹介したいと思います。`}</p>
    <h1>{`呼び鈴アプリの開発背景`}</h1>
    <p>{`弊社では、チャットツールである「`}<a parentName="p" {...{
        "href": "https://slack.com/intl/ja-jp/enterprise"
      }}>{`Slack`}</a>{`」と「`}<a parentName="p" {...{
        "href": "https://workspace.google.co.jp/intl/ja/features/"
      }}>{`Google Workspace`}</a>{`」や「`}<a parentName="p" {...{
        "href": "https://www.teamspirit.com/ja-jp/"
      }}>{`TeamSpirit`}</a>{`」等を連携して、来客者受付システムや`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2020/12/25/180058"
      }}>{`稟議ワークフローシステム`}</a>{`、オフィス空調最適化システム等、チャットをベースにした業務運用（ChatOps）を数多く行っています。`}</p>
    <p>{`今回はその中でも、サポートを受けたい場合にコーポレート部門の担当者を呼び出しできるアプリ、`}<strong parentName="p">{`通称：呼び鈴アプリ`}</strong>{` をご紹介しようと思います。`}</p>
    <h2>{`呼び鈴アプリ導入前の困りごと`}</h2>
    <p>{`IT 備品や健康保険証等の物の受け渡しや PC トラブルへの対応など、従業員へのサポート対応の際、サポートを依頼した従業員とコーポレート部門との間で、以下のような困りごとが発生していました。`}</p>
    <h3>{`■ 従業員側の困りごと`}</h3>
    <h4>{`・誰に話しかけたらよいかわからない`}</h4>
    <p>{`IT 備品や健康保険証等を受け取りにコーポレート部門の座席に来たが、誰に話しかけたらよいかわからず、ウロウロしている方が結構いました。結果、別の部門に話しかけて、別の部門の方が正しい部門に案内するという時間も発生していました。`}</p>
    <h4>{`・時間調整に手間がかかる`}</h4>
    <p>{`PC トラブルへの対応の際は、コーポレート IT の担当者が依頼者の席まで行って作業をしていたため、担当者と依頼者で時間の調整が必要でした。直前で都合が悪くなり、リスケが発生することもしばしばありました。`}</p>
    <h3>{`■ コーポレート部門の困りごと`}</h3>
    <h4>{`・依頼者が見つけづらい`}</h4>
    <p>{`座席表を見て依頼者のところまで行くが、直近で座席変更があって居ると思っていたところに居ないことや、指定の時間に席に居ないといったことが度々発生していました。`}</p>
    <h4>{`・個人情報が見えると困る`}</h4>
    <p>{`従業員が人事部から健康保険証等の書類を受け取る際、人事部の方は個人情報を扱うため、PC 画面の見える位置まで来られると困るという事情があり、特定の位置で待ってもらいたいというニーズがありました。`}</p>
    <h4>{`・従業員の氏名確認に手間がかかる`}</h4>
    <p>{`全従業員分の氏名は把握できないため、コーポレート部門に来た従業員本人に名前を聞いて確認し、一旦席に戻って、貸与する IT 備品や健康保険証等を探して渡すという、席を行ったり来たりする状況でした。`}</p>
    <h4>{`・周りの業務状況に配慮が必要`}</h4>
    <p>{`依頼者の席で PC トラブル対応をすると、周りの人が業務に集中していたり、顧客と電話していたりするため、業務に支障がでないようになるべく小声で話す必要がありました。`}</p>
    <h4>{`・依頼者への対応が属人化しがち`}</h4>
    <p>{`コーポレート部門の担当者と依頼者の間で作業の時間を調整していましたが、担当者が急な打ち合わせやお休みで不在になってしまったときは、他の担当者への引き継ぎ対応が漏れてしまうことがありました。`}</p>
    <p>{`上記の課題を解決するために、`}<strong parentName="p">{`従業員の方向けにコーポレート部門の担当者を呼び出すアプリ`}</strong>{`を作りました！`}</p>
    <h2>{`どういったアプリか`}</h2>
    <p>{`呼び鈴アプリは、従業員とコーポレート部門で対面での作業/受け渡しが発生すること（PC トラブルへの対応/落とし物の受け渡し/健康保険証のお渡し等）に関して、iPad で依頼内容に応じた`}<strong parentName="p">{`コーポレート部門の担当者を呼び出すことができるアプリ`}</strong>{`です。`}</p>
    <p>{`コーポレート部門の近くのカウンターの上に iPad が置いてあり、そこから呼び出してもらいます。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7c65806aae9094b9853af091e36d5bcb/d2602/note1223_006.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgED/9oADAMBAAIQAxAAAAFqmnOFkbM3/8QAGhAAAgIDAAAAAAAAAAAAAAAAAQIAEQMSIv/aAAgBAQABBQKZDyaMaljndxQH/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BiP/EABcRAQADAAAAAAAAAAAAAAAAAAABERL/2gAIAQIBAT8BtqX/xAAaEAACAgMAAAAAAAAAAAAAAAAAAREhEBIx/9oACAEBAAY/AisQ7k1Rw//EABoQAAIDAQEAAAAAAAAAAAAAAAERACFBMXH/2gAIAQEAAT8hHGaiRLEaoJHZ8EtMLEM25mRULez/2gAMAwEAAgADAAAAEE8f/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAwEBPxBbhJ//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAECAQE/EHnSY//EABsQAQEAAwADAAAAAAAAAAAAAAERACExQXGh/9oACAEBAAE/EIrQO15mrCaoQ95W2haE+4C0WV8wWYBNIHJaINo85//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note1223 006",
            "title": "note1223 006",
            "src": "/static/7c65806aae9094b9853af091e36d5bcb/e5166/note1223_006.jpg",
            "srcSet": ["/static/7c65806aae9094b9853af091e36d5bcb/f93b5/note1223_006.jpg 300w", "/static/7c65806aae9094b9853af091e36d5bcb/b4294/note1223_006.jpg 600w", "/static/7c65806aae9094b9853af091e36d5bcb/e5166/note1223_006.jpg 1200w", "/static/7c65806aae9094b9853af091e36d5bcb/d9c39/note1223_006.jpg 1800w", "/static/7c65806aae9094b9853af091e36d5bcb/df51d/note1223_006.jpg 2400w", "/static/7c65806aae9094b9853af091e36d5bcb/d2602/note1223_006.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`ちなみに、カウンターはこんな感じのスペースで、従業員の方がセルフサービスで IT 備品交換を行えるブースもあります。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1011px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/94caada15fd1d300de3ef8c1b421add4/6fbf4/note1223_015.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAbvF8r0EBn//xAAbEAABBAMAAAAAAAAAAAAAAAAAAQIREgMTIv/aAAgBAQABBQKRtKNVka75F5JP/8QAFREBAQAAAAAAAAAAAAAAAAAAABL/2gAIAQMBAT8BlL//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEv/aAAgBAgEBPwGlP//EAB4QAAIBAwUAAAAAAAAAAAAAAAABEQIQIRIiMXGR/9oACAEBAAY/AsafTLlm7noqUwQrf//EABoQAAMBAQEBAAAAAAAAAAAAAAABETEhcUH/2gAIAQEAAT8hdKunysMmpxxwz99CcA7xCqcDyf/aAAwDAQACAAMAAAAQDO//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPxBh/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QI//EABwQAQEAAgMBAQAAAAAAAAAAAAERACExQVGRsf/aAAgBAQABPxBHChcTbeDeKFCUJnqZukR9ofMGiDPYPWLE1AC8TAhP1n//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note1223 015",
            "title": "note1223 015",
            "src": "/static/94caada15fd1d300de3ef8c1b421add4/6fbf4/note1223_015.jpg",
            "srcSet": ["/static/94caada15fd1d300de3ef8c1b421add4/f93b5/note1223_015.jpg 300w", "/static/94caada15fd1d300de3ef8c1b421add4/b4294/note1223_015.jpg 600w", "/static/94caada15fd1d300de3ef8c1b421add4/6fbf4/note1223_015.jpg 1011w"],
            "sizes": "(max-width: 1011px) 100vw, 1011px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`アプリの利用イメージ`}</h3>
    <p>{`まず、従業員はカウンターまで行き、iPad の呼び鈴アプリから呼び出し依頼を行います。`}</p>
    <p>{`呼び出し依頼は専用の Slack チャンネルに通知が来るので、各コーポレート部門の担当者が通知内容を確認して、従業員が居るカウンターに行き、対応という流れになります。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2ef77f37eaaf63600a16587d20c558ae/8d688/note1223_005.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3QUH/8QAFhABAQEAAAAAAAAAAAAAAAAAABFB/9oACAEBAAEFAm1//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERUTFx/9oACAEBAAE/IWrHhOq/BaP/2gAMAwEAAgADAAAAEAQf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EMV//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGRABAAMBAQAAAAAAAAAAAAAAAQARITFh/9oACAEBAAE/EEbLq+eQSKoCWOwtECNa9n//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note1223 005",
            "title": "note1223 005",
            "src": "/static/2ef77f37eaaf63600a16587d20c558ae/e5166/note1223_005.jpg",
            "srcSet": ["/static/2ef77f37eaaf63600a16587d20c558ae/f93b5/note1223_005.jpg 300w", "/static/2ef77f37eaaf63600a16587d20c558ae/b4294/note1223_005.jpg 600w", "/static/2ef77f37eaaf63600a16587d20c558ae/e5166/note1223_005.jpg 1200w", "/static/2ef77f37eaaf63600a16587d20c558ae/8d688/note1223_005.jpg 1791w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`画面イメージ`}</h3>
    <p>{`利用方法は、自身の名前を選択してもらい、コーポレート部門への依頼内容を選択するという形です。
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3667ca78e8560cb1330fc05e1903f8d2/b9cf9/note1223_001.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQD/2gAMAwEAAhADEAAAAdoDRP8A/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAERAiL/2gAIAQEAAQUCcRMnJ//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABUQAQEAAAAAAAAAAAAAAAAAAABB/9oACAEBAAY/AoiP/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAERIVGh/9oACAEBAAE/IUyiUqsXknJ//9oADAMBAAIAAwAAABAP7//EABgRAAMBAQAAAAAAAAAAAAAAAAABIRFR/9oACAEDAQE/EFVpen//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAEAAgIDAAAAAAAAAAAAAAABACERMVGhwf/aAAgBAQABPxAvhrQVuDqlxaHaBnkT/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note1223 001",
            "title": "note1223 001",
            "src": "/static/3667ca78e8560cb1330fc05e1903f8d2/e5166/note1223_001.jpg",
            "srcSet": ["/static/3667ca78e8560cb1330fc05e1903f8d2/f93b5/note1223_001.jpg 300w", "/static/3667ca78e8560cb1330fc05e1903f8d2/b4294/note1223_001.jpg 600w", "/static/3667ca78e8560cb1330fc05e1903f8d2/e5166/note1223_001.jpg 1200w", "/static/3667ca78e8560cb1330fc05e1903f8d2/d9c39/note1223_001.jpg 1800w", "/static/3667ca78e8560cb1330fc05e1903f8d2/df51d/note1223_001.jpg 2400w", "/static/3667ca78e8560cb1330fc05e1903f8d2/b9cf9/note1223_001.jpg 3315w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`導入後の効果`}</h2>
    <p>{`呼び鈴アプリの導入によって、`}</p>
    <ul>
      <li parentName="ul">{`従業員がコーポレート部門からサポートを受けたり、物を受け渡ししたいときは、`}<strong parentName="li">{`自身が都合の良いタイミングで`}</strong>{`カウンターの呼び鈴アプリに来れば良く、`}<strong parentName="li">{`利便性が向上した`}</strong>{`。`}</li>
      <li parentName="ul">{`Slack 通知に依頼者氏名が表示されるので、`}<strong parentName="li">{`氏名を確認する必要がなくなった。`}</strong></li>
      <li parentName="ul">{`コーポレート部門の担当者が`}<strong parentName="li">{`依頼者の席を探して尋ねなくてよくなった。`}</strong></li>
    </ul>
    <p>{`というように困りごとが改善されました。`}</p>
    <p>{`また、特定のメンバーが対応するのではなく、呼び出されたタイミングで席に居る全メンバーが対応できるような運用にすることで、PC/IT 備品や健康保険証等の物の受け渡し、PC のトラブル対応等のサポート対応が属人的にならず、離席していても、休暇をとっていても誰でも対応できる体制になりました。`}</p>
    <h1>{`呼び鈴アプリの構成`}</h1>
    <p>{`呼び鈴アプリの開発にあたっては`}</p>
    <p><strong parentName="p">{`1. 先述の課題を解決すること`}</strong></p>
    <p><strong parentName="p">{`2. コーポレート IT 内でプロジェクトが複数動いているため、他のプロジェクトの進捗に影響がでないように、工数をさほどかけずにクイックに開発できること`}</strong></p>
    <p><strong parentName="p">{`3. なるべくランニングコストをかけないこと`}</strong></p>
    <p>{`という要件から、`}<strong parentName="p">{`AppSheet`}</strong>{` と `}<a parentName="p" {...{
        "href": "https://workspace.google.co.jp/intl/ja/products/apps-script/"
      }}><strong parentName="a">{`Google App Script（以降 GAS）`}</strong></a>{`で実装することを決めました。`}</p>
    <p>{`機能検証期間を含めて 1 週間程度で実装できています。`}</p>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://cloud.google.com/appsheet?hl=ja"
        }}>{`AppSheet`}</a>{` は、Google から提供されている、ノーコードでアプリケーション開発ができるツールです。`}</p>
      <p parentName="blockquote">{`AppSheet は、エンジニアではなく「現場で働く人が作る」という思想をもとにサービスが開始されました。そのため、プログラミング知識がない人でも、マウス操作のみで簡単にアプリケーションを開発できるようになっています。`}</p>
    </blockquote>
    <h2>{`サービス連携図`}</h2>
    <p>{`UI を AppSheet で、Slack への通知を GAS で実装しています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9a299c23f603a4d04165561333843a04/b29e7/note1223_004.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB7i2xJjC//8QAGhAAAgIDAAAAAAAAAAAAAAAAAQIAERAhMf/aAAgBAQABBQILUC0F0MDn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhABAQEAAAAAAAAAAAAAAAAAMQAg/9oACAEBAAY/Amc//8QAGhABAQEBAAMAAAAAAAAAAAAAAREAIRAxUf/aAAgBAQABPyGCU51Gu70VV+zw9ExhN//aAAwDAQACAAMAAAAQhM//xAAWEQADAAAAAAAAAAAAAAAAAAAQESH/2gAIAQMBAT8QVH//xAAWEQADAAAAAAAAAAAAAAAAAAABECH/2gAIAQIBAT8QNX//xAAbEAEAAgMBAQAAAAAAAAAAAAABESEAMUEQkf/aAAgBAQABPxBhUZW+zzJIiSPL45SIN0z88O0JIrCOmA7n/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note1223 004",
            "title": "note1223 004",
            "src": "/static/9a299c23f603a4d04165561333843a04/e5166/note1223_004.jpg",
            "srcSet": ["/static/9a299c23f603a4d04165561333843a04/f93b5/note1223_004.jpg 300w", "/static/9a299c23f603a4d04165561333843a04/b4294/note1223_004.jpg 600w", "/static/9a299c23f603a4d04165561333843a04/e5166/note1223_004.jpg 1200w", "/static/9a299c23f603a4d04165561333843a04/b29e7/note1223_004.jpg 1332w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`画面構成`}</h2>
    <p>{`画面は依頼者選択画面、依頼内容一覧画面、依頼詳細一覧画面の３画面で構成しています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6b3a27d355b52d72be18be13b64621be/b9cf9/note1223_002.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQD/2gAMAwEAAhADEAAAAdyAhP8A/8QAGBAAAgMAAAAAAAAAAAAAAAAAABEBAiL/2gAIAQEAAQUCUCqsn//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABUQAQEAAAAAAAAAAAAAAAAAAABB/9oACAEBAAY/AkR//8QAGBAAAwEBAAAAAAAAAAAAAAAAAAERIVH/2gAIAQEAAT8hlcSFRAlyP//aAAwDAQACAAMAAAAQC+//xAAYEQACAwAAAAAAAAAAAAAAAAAAAREhUf/aAAgBAwEBPxBSy9P/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPxBn/8QAGRABAQEBAQEAAAAAAAAAAAAAAREAITGB/9oACAEBAAE/EIkMj2YhDo7J5hEB+m//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note1223 002",
            "title": "note1223 002",
            "src": "/static/6b3a27d355b52d72be18be13b64621be/e5166/note1223_002.jpg",
            "srcSet": ["/static/6b3a27d355b52d72be18be13b64621be/f93b5/note1223_002.jpg 300w", "/static/6b3a27d355b52d72be18be13b64621be/b4294/note1223_002.jpg 600w", "/static/6b3a27d355b52d72be18be13b64621be/e5166/note1223_002.jpg 1200w", "/static/6b3a27d355b52d72be18be13b64621be/d9c39/note1223_002.jpg 1800w", "/static/6b3a27d355b52d72be18be13b64621be/df51d/note1223_002.jpg 2400w", "/static/6b3a27d355b52d72be18be13b64621be/b9cf9/note1223_002.jpg 3315w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h1>{`呼び鈴アプリの実装`}</h1>
    <p>{`呼び鈴アプリをどのように実装しているか解説していきます。`}</p>
    <h2>{`AppSheet の設定`}</h2>
    <p>{`AppSheet を利用したアプリの作成方法については、Google 公式の `}<a parentName="p" {...{
        "href": "https://about.appsheet.com/how-to-create-an-app/"
      }}>{`How to create an app`}</a>{` や Google で AppSheet を検索するといくつか作成例のサイトが表示されますので、今回の機能を実装するにあたり重要な `}<strong parentName="p">{`「依頼詳細一覧画面から Slack 通知」`}</strong>{` 設定の部分にフォーカスして解説します。`}</p>
    <h3>{`1. Data の設定`}</h3>
    <p>{`Tables と Slices について、それぞれ設定します。`}</p>
    <blockquote>
      <p parentName="blockquote">{`Tables では、アプリケーションの元となるデータを格納する「テーブル」を作成します。
Slices では、テーブルから必要な行と列のみを表示させたり、条件にあうデータのみを表示させたりして、必要なデータを抽出した「テーブルの一部分」を作成します。`}</p>
    </blockquote>
    <h4>{`1-1. Tables の設定`}</h4>
    <p>{`今回 Table は以下の 5 つを用意しました。`}</p>
    <p>{`テーブルは、スプレッドシートにテーブルとなるデータを作成した後、AppSheet と連携します。連携方法は `}<a parentName="p" {...{
        "href": "https://about.appsheet.com/how-to-create-an-app/#step2"
      }}>{`Connect your data to AppSheet`}</a>{` の動画を参考にしてください。`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`テーブル名`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`利用用途`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Client`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`依頼者選択画面で選択されたユーザー名を一時的に保存するテーブル`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Doorbell`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`依頼内容一覧画面に表示するテーブル`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DoorbellDetails`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`依頼詳細一覧画面に表示するテーブル`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CallHistory`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`呼出履歴を保存するテーブル`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`UserList`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`依頼者選択画面に表示するテーブル`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`依頼詳細一覧画面と呼び出し機能に関連する、「DoorbellDetails」と「CallHistory」について詳しく説明します。`}</p>
    <h5>{`・ DoorbellDetails`}</h5>
    <p>{`依頼詳細一覧画面に表示したい内容を一覧で記載します。`}</p>
    <p>{`分類 ID 列は Doorbell でも同名の列を作成し、そこで設定した値と合わせてください。また、対応 ID は分類 ID 内でユニークになるように設定してください。`}</p>
    <p>{`対応説明に関しては、任意で入力していただければ大丈夫です。`}</p>
    <p>{`SlackChannel 列に Slack チャンネルに投稿する用の Webhook URL を設定してください。`}<a parentName="p" {...{
        "href": "https://slack.com/apps/A0F7XDUAZ-incoming-webhooks"
      }}>{`Incoming Webhooks`}</a>{` 等を使用すると、 Webhook URL が取得できます。`}</p>
    <p>{`3 行目以降のデータは、 2 行目を参考に設定してください。`}</p>
    <p><strong parentName="p">{`スプレッドシートへのデータ入力例`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`行番号`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`A 列`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`B 列`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`C 列`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`D 列`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`E 列`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`分類 ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`対応 ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`対応名`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`対応説明`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SlackChannel`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`receipt`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`入社にあたって貸与物を受け取りたい`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`（通知先チャンネルの Webhook URL を設定）`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Table での設定例`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cc1b28b1f04c8f11207dd22f99deb30d/684df/note1223_008c.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAFABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3aFB/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABPyF//9oADAMBAAIAAwAAABCAD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABgQAQADAQAAAAAAAAAAAAAAAAEAITFh/9oACAEBAAE/EArYnWBWz//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note1223 008c",
            "title": "note1223 008c",
            "src": "/static/cc1b28b1f04c8f11207dd22f99deb30d/e5166/note1223_008c.jpg",
            "srcSet": ["/static/cc1b28b1f04c8f11207dd22f99deb30d/f93b5/note1223_008c.jpg 300w", "/static/cc1b28b1f04c8f11207dd22f99deb30d/b4294/note1223_008c.jpg 600w", "/static/cc1b28b1f04c8f11207dd22f99deb30d/e5166/note1223_008c.jpg 1200w", "/static/cc1b28b1f04c8f11207dd22f99deb30d/d9c39/note1223_008c.jpg 1800w", "/static/cc1b28b1f04c8f11207dd22f99deb30d/df51d/note1223_008c.jpg 2400w", "/static/cc1b28b1f04c8f11207dd22f99deb30d/684df/note1223_008c.jpg 4578w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`分類 ID と対応 ID を複合キー（分類 ID と対応 ID に「REQUIRE」を設定）としています。`}</p>
    <h5>{`・ CallHistory`}</h5>
    <p>{`呼出履歴を保存するテーブルのため、ヘッダー以外の入力は不要です。`}</p>
    <p><strong parentName="p">{`スプレッドシートへのデータ入力例`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`行番号`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`A 列`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`B 列`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`C 列`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`D 列`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`E 列`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`F 列`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`G 列`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CH_id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CH`}{`_`}{`分類 ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CH`}{`_`}{`対応 ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CH`}{`_`}{`対応名`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CH_SlackChannel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CH`}{`_`}{`呼び出し日時`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CH`}{`_`}{`依頼者`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Table での設定例`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c6e8c2a67655f60289adf990caecf6ff/1776a/note1223_008d.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.333333333333332%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3aAH/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABPyF//9oADAMBAAIAAwAAABCDz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAAMAAwAAAAAAAAAAAAAAAAABIRExgf/aAAgBAQABPxC42dE4f//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note1223 008d",
            "title": "note1223 008d",
            "src": "/static/c6e8c2a67655f60289adf990caecf6ff/e5166/note1223_008d.jpg",
            "srcSet": ["/static/c6e8c2a67655f60289adf990caecf6ff/f93b5/note1223_008d.jpg 300w", "/static/c6e8c2a67655f60289adf990caecf6ff/b4294/note1223_008d.jpg 600w", "/static/c6e8c2a67655f60289adf990caecf6ff/e5166/note1223_008d.jpg 1200w", "/static/c6e8c2a67655f60289adf990caecf6ff/d9c39/note1223_008d.jpg 1800w", "/static/c6e8c2a67655f60289adf990caecf6ff/df51d/note1223_008d.jpg 2400w", "/static/c6e8c2a67655f60289adf990caecf6ff/1776a/note1223_008d.jpg 4672w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4>{`1-2. Slices の設定`}</h4>
    <p>{`次に、Slice の設定を行います。Slice は依頼内容ごとに画面表示を切り替えるために「DoorbellDetails」のデータを元に作成します。`}</p>
    <p>{`貸与物受け取りに関する Slice の場合、以下のように設定します。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "963px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/18a197c625f6cf0051dd3b65772e592b/b03a8/note1223_008f.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3aFB/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQABBQJf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAFhABAQEAAAAAAAAAAAAAAAAAEEGB/9oACAEBAAE/IY4//9oADAMBAAIAAwAAABBAD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAAICAgMAAAAAAAAAAAAAAAABESFBYTFR8P/aAAgBAQABPxDRRZz5ELoTq8idGEf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note1223 008f",
            "title": "note1223 008f",
            "src": "/static/18a197c625f6cf0051dd3b65772e592b/b03a8/note1223_008f.jpg",
            "srcSet": ["/static/18a197c625f6cf0051dd3b65772e592b/f93b5/note1223_008f.jpg 300w", "/static/18a197c625f6cf0051dd3b65772e592b/b4294/note1223_008f.jpg 600w", "/static/18a197c625f6cf0051dd3b65772e592b/b03a8/note1223_008f.jpg 963w"],
            "sizes": "(max-width: 963px) 100vw, 963px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`設定項目名`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`設定値`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Slice name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`貸与物受け取り`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Source table`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DoorbellDetails`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Row filter condition`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><code parentName="td" {...{
              "className": "language-text"
            }}>{`[分類 ID]="receipt"`}</code></td>
        </tr>
      </tbody>
    </table>
    <h3>{`2. App の設定`}</h3>
    <p>{`Views について、それぞれ設定します。`}</p>
    <h4>{`2-1. Views の設定`}</h4>
    <p>{`依頼詳細一覧画面について詳しく説明します。`}</p>
    <h5>{`・依頼詳細一覧画面`}</h5>
    <p>{`依頼内容一覧画面から遷移できるように、依頼詳細一覧画面は依頼内容ごとに準備します。ここでは貸与物受け取り画面を例として設定しています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d8a06be570c0337405ffe723fb4ce590/e993b/note1223_009e.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "154%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAfABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe7VAM1QD//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEAAQUCT//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CT//EABkQAQACAwAAAAAAAAAAAAAAABABEQAhUf/aAAgBAQABPyGtMPWMo//aAAwDAQACAAMAAAAQsMAM/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAGxABAAIDAQEAAAAAAAAAAAAAAQAREDFxIeH/2gAIAQEAAT8QsJqmHp8lEfj2UYKrrBqDfYCan//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note1223 009e",
            "title": "note1223 009e",
            "src": "/static/d8a06be570c0337405ffe723fb4ce590/e5166/note1223_009e.jpg",
            "srcSet": ["/static/d8a06be570c0337405ffe723fb4ce590/f93b5/note1223_009e.jpg 300w", "/static/d8a06be570c0337405ffe723fb4ce590/b4294/note1223_009e.jpg 600w", "/static/d8a06be570c0337405ffe723fb4ce590/e5166/note1223_009e.jpg 1200w", "/static/d8a06be570c0337405ffe723fb4ce590/e993b/note1223_009e.jpg 1766w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/aade2205c1b5a372a4c30957ee830f10/b78e1/note1223_009f.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe61SA//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAEFAl//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAYEAACAwAAAAAAAAAAAAAAAAAAAREggf/aAAgBAQABPyGUbX//2gAMAwEAAgADAAAAEODP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxABAAICAwAAAAAAAAAAAAAAARExACAhYZH/2gAIAQEAAT8QOQFHvJlr7hVzp//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note1223 009f",
            "title": "note1223 009f",
            "src": "/static/aade2205c1b5a372a4c30957ee830f10/e5166/note1223_009f.jpg",
            "srcSet": ["/static/aade2205c1b5a372a4c30957ee830f10/f93b5/note1223_009f.jpg 300w", "/static/aade2205c1b5a372a4c30957ee830f10/b4294/note1223_009f.jpg 600w", "/static/aade2205c1b5a372a4c30957ee830f10/e5166/note1223_009f.jpg 1200w", "/static/aade2205c1b5a372a4c30957ee830f10/b78e1/note1223_009f.jpg 1774w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`設定項目名`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`設定値`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`貸与物受け取り画面`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`For this data`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`貸与物受け取り`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`View type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`deck`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Position`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ref`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Primary header`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`_`}{`RowNumber`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Secondary header`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`対応名`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Event Actions(Row Selected)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SaveAndMoveToClientForm（Actions の設定後、設定可能になります）`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`3. Actions の設定`}</h3>
    <p>{`各ボタンを押したときの動作を定義します。`}</p>
    <p>{`実際には他にもいくつか Action を設定していますが、Slack 通知に関係する Action は以下の 3 つです。`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Action 名`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`利用用途`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MoveToClientForm`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`依頼者選択画面に遷移する`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SaveCallHistory`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CallHistory にデータを保存する`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SaveAndMoveToClientForm`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CallHistory に保存後、依頼者選択画面に遷移する`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`・ MoveToClientForm`}</h4>
    <p>{`依頼者選択画面に遷移する Action です。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b73e6784fb9924da2a79f2652d085881/72000/note1223_010c.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3apAf//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEAAQUCX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABUQAQEAAAAAAAAAAAAAAAAAACBB/9oACAEBAAE/IYv/2gAMAwEAAgADAAAAECPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGRABAQEAAwAAAAAAAAAAAAAAAQARIUFh/9oACAEBAAE/EMxPB7kIncBDhbLf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note1223 010c",
            "title": "note1223 010c",
            "src": "/static/b73e6784fb9924da2a79f2652d085881/e5166/note1223_010c.jpg",
            "srcSet": ["/static/b73e6784fb9924da2a79f2652d085881/f93b5/note1223_010c.jpg 300w", "/static/b73e6784fb9924da2a79f2652d085881/b4294/note1223_010c.jpg 600w", "/static/b73e6784fb9924da2a79f2652d085881/e5166/note1223_010c.jpg 1200w", "/static/b73e6784fb9924da2a79f2652d085881/72000/note1223_010c.jpg 1794w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`設定項目名`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`設定値`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MoveToClientForm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`For a record of this table`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DoorbellDetails`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Do this`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`App: go to another view within this app`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Target`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"#view=依頼者選択"`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`・ SaveCallHistory`}</h4>
    <p>{`「CallHistory」テーブルにデータを保存する Action です。`}</p>
    <p>{`選択された氏名については「Client」テーブルからデータを取得しています。`}</p>
    <p>{`また、SlackChannel 列の記載がない場合は、画面に「呼び出し」ボタンを表示させないように設定しています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/49f7c79aadb3c0e6c7be7686770641df/d9c39/note1223_010d1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "95%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAATABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe7UNIKAD//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEAAQUCH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CH//EABsQAAICAwEAAAAAAAAAAAAAAAABEBEhMUGR/9oACAEBAAE/IeHsPCLFqKUf/9oADAMBAAIAAwAAABAAwAD/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAaEAEBAQEBAQEAAAAAAAAAAAABEQAxIXGR/9oACAEBAAE/EAKSW4+fjQwrLqp670MlI6nQ3Ob/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note1223 010d1",
            "title": "note1223 010d1",
            "src": "/static/49f7c79aadb3c0e6c7be7686770641df/e5166/note1223_010d1.jpg",
            "srcSet": ["/static/49f7c79aadb3c0e6c7be7686770641df/f93b5/note1223_010d1.jpg 300w", "/static/49f7c79aadb3c0e6c7be7686770641df/b4294/note1223_010d1.jpg 600w", "/static/49f7c79aadb3c0e6c7be7686770641df/e5166/note1223_010d1.jpg 1200w", "/static/49f7c79aadb3c0e6c7be7686770641df/d9c39/note1223_010d1.jpg 1800w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5f4e28f37346fa0458b2c07d5bfa020d/62955/note1223_010d2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "112.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAWABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe7NAogKD//EABYQAAMAAAAAAAAAAAAAAAAAAAARMP/aAAgBAQABBQIcP//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CH//EABsQAAICAwEAAAAAAAAAAAAAAAARARAhMZFh/9oACAEBAAE/IdQxSMwdF5SO1//aAAwDAQACAAMAAAAQwwcA/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHRABAAIBBQEAAAAAAAAAAAAAAQARMRAhQVFhkf/aAAgBAQABPxBLk896LLCCNkAvKG2CoAv32Ud/Uuf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note1223 010d2",
            "title": "note1223 010d2",
            "src": "/static/5f4e28f37346fa0458b2c07d5bfa020d/e5166/note1223_010d2.jpg",
            "srcSet": ["/static/5f4e28f37346fa0458b2c07d5bfa020d/f93b5/note1223_010d2.jpg 300w", "/static/5f4e28f37346fa0458b2c07d5bfa020d/b4294/note1223_010d2.jpg 600w", "/static/5f4e28f37346fa0458b2c07d5bfa020d/e5166/note1223_010d2.jpg 1200w", "/static/5f4e28f37346fa0458b2c07d5bfa020d/d9c39/note1223_010d2.jpg 1800w", "/static/5f4e28f37346fa0458b2c07d5bfa020d/62955/note1223_010d2.jpg 1802w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`設定項目名`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`設定値`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SaveCallHistory`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`For a record of this table`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DoorbellDetails`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Do this`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Data: add a new row to another table using values from this row`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Set these columns`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CH_id = UNIQUEID()`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CH`}{`_`}{`分類 ID = `}{`[`}{`分類 ID`}{`]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CH`}{`_`}{`対応 ID = `}{`[`}{`対応 ID`}{`]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CH`}{`_`}{`対応名 = `}{`[`}{`対応名`}{`]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CH_SlackChannel = `}{`[`}{`SlackChannel`}{`]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CH`}{`_`}{`呼び出し日次 = TODAY() & " " & TIMENOW()`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CH`}{`_`}{`依頼者 = Client`}{`[`}{`ご自身の氏名`}{`]`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Display name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`呼び出し`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Only if this condition is true`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ISNOTBLANK(`}{`[`}{`SlackChannel`}{`]`}{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Needs confirmation?`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`True`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Confirmation Message`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CONCATENATE("依頼者：",Client`}{`[`}{`ご自身の氏名`}{`]`}{`,"","呼び出し内容：",`}{`[`}{`対応名`}{`]`}{`,"","担当者を呼び出しますか？")`}</td>
        </tr>
      </tbody>
    </table>
    <h5>{`・ SaveAndMoveToClientForm`}</h5>
    <p>{`呼出履歴に保存後、依頼者選択画面に遷移する Action です。この Action を依頼詳細一覧画面の Event Actions に紐付けます。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/49559a4e4562b0bdbf96940311f496f8/6a068/note1223_010f.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3bQB/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQABBQJf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAFRABAQAAAAAAAAAAAAAAAAAAIEH/2gAIAQEAAT8hi//aAAwDAQACAAMAAAAQAA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAZEAEBAAMBAAAAAAAAAAAAAAABABEhYVH/2gAIAQEAAT8QwAdHSE7YLSbsHk3/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note1223 010f",
            "title": "note1223 010f",
            "src": "/static/49559a4e4562b0bdbf96940311f496f8/6a068/note1223_010f.jpg",
            "srcSet": ["/static/49559a4e4562b0bdbf96940311f496f8/f93b5/note1223_010f.jpg 300w", "/static/49559a4e4562b0bdbf96940311f496f8/b4294/note1223_010f.jpg 600w", "/static/49559a4e4562b0bdbf96940311f496f8/6a068/note1223_010f.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`設定項目名`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`設定値`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SaveAndMoveToClientForm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`For a record of this table`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DoorbellDetails`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Do this`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Grouped: execute a sequence of actions`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Actions`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SaveCallHistory`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MoveToClientForm`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`スプレッドシート の設定`}</h2>
    <p>{`AppSheet からスプレッドシートの「CallHistory」シートに呼出履歴データが登録（行追加）されたら、Slack 通知 GAS を実行するように設定します。`}</p>
    <h3>{`GAS の記述`}</h3>
    <p>{`先程テーブルを作成したスプレッドシートの拡張機能 →Apps Script を開きます。`}</p>
    <p>{`App Script 上で新しく「.gs」ファイルを作成し、「CallHistory」 のシートが更新されたときだけ、Slack に通知するように GAS を記述します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`onChange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`e`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// イベントが"EDIT"の場合のみ実行。`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`changeType `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"EDIT"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` ar `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`source`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getActiveRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` sheet `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`source`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getActiveSheet`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// CallHistory シートが更新された場合のみ Slack に通知する`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`e`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`source`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getSheetName`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"CallHistory"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` inquiry `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` sheet`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getRow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getValue`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` slackWebHook `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` sheet`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getRow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getValue`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` clientName `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` sheet`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getRange`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ar`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getRow`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getValue`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

      `}<span parentName="code" {...{
            "className": "token function"
          }}>{`notifyToSlack`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
        slackWebHook`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"コーポカウンター呼び鈴"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token string"
          }}>{`":notification_bell:"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// お好きな Slack の絵文字を設定して下さい。`}</span>{`
        `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span><span parentName="span" {...{
              "className": "token string"
            }}>{`<!here>
        `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`clientName`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`さんが`}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`inquiry`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`で呼び出ししています。`}</span><span parentName="span" {...{
              "className": "token template-punctuation string"
            }}>{`\``}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`notifyToSlack`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`url`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` username`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` icon`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` message`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` jsonData `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`username`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` username`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`icon_emoji`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` icon`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`text`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` payload `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`JSON`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`stringify`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`jsonData`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` options `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`method`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"post"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`contentType`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"application/json"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token literal-property property"
          }}>{`payload`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` payload`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

  UrlFetchApp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`fetch`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`url`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` options`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3>{`トリガーの設定`}</h3>
    <p>{`スプレッドシートに呼出履歴が登録（行追加）されたら、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`onChange()`}</code>{` を実行するように設定します。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5bdf2d48c452e1241df25058ddec15dc/801ec/note1223_007.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "98%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHuaolojQgWf//EABcQAAMBAAAAAAAAAAAAAAAAAAAQESD/2gAIAQEAAQUCUxSr/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFhEAAwAAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/Aaz/xAAWEAADAAAAAAAAAAAAAAAAAAAAMDH/2gAIAQEABj8CIj//xAAbEAEBAAIDAQAAAAAAAAAAAAABABFREEFx8f/aAAgBAQABPyEDVg+JGiOG8FnZTtfb/9oADAMBAAIAAwAAABDQAAD/xAAXEQADAQAAAAAAAAAAAAAAAAAAARAx/9oACAEDAQE/EKsP/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAx/9oACAECAQE/EHR//8QAHBAAAwACAwEAAAAAAAAAAAAAAAERMWEhkbHB/9oACAEBAAE/EMOehpa4MU/IyiEZieLwNSK3bHdNH//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note1223 007",
            "title": "note1223 007",
            "src": "/static/5bdf2d48c452e1241df25058ddec15dc/e5166/note1223_007.jpg",
            "srcSet": ["/static/5bdf2d48c452e1241df25058ddec15dc/f93b5/note1223_007.jpg 300w", "/static/5bdf2d48c452e1241df25058ddec15dc/b4294/note1223_007.jpg 600w", "/static/5bdf2d48c452e1241df25058ddec15dc/e5166/note1223_007.jpg 1200w", "/static/5bdf2d48c452e1241df25058ddec15dc/801ec/note1223_007.jpg 1442w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`問題なく動作すると、Slack に以下のようなメッセージが通知されます。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4a9b95f5a889d3ccd467db10772871c2/a4413/note1223_013.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "15.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAADABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdywSD//xAAVEAEBAAAAAAAAAAAAAAAAAAAQQf/aAAgBAQABBQKn/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFhABAQEAAAAAAAAAAAAAAAAAARAh/9oACAEBAAE/IQ1f/9oADAMBAAIAAwAAABDzz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAQEAAwEAAAAAAAAAAAAAAAEAMUFhkf/aAAgBAQABPxAMO4Ovs3//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note1223 013",
            "title": "note1223 013",
            "src": "/static/4a9b95f5a889d3ccd467db10772871c2/e5166/note1223_013.jpg",
            "srcSet": ["/static/4a9b95f5a889d3ccd467db10772871c2/f93b5/note1223_013.jpg 300w", "/static/4a9b95f5a889d3ccd467db10772871c2/b4294/note1223_013.jpg 600w", "/static/4a9b95f5a889d3ccd467db10772871c2/e5166/note1223_013.jpg 1200w", "/static/4a9b95f5a889d3ccd467db10772871c2/a4413/note1223_013.jpg 1312w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`実装する上で難しかったこと`}</h3>
    <p>{`AppSheet でアプリを開発するにあたり、ノーコード開発の制約でかゆいところに手が届かず、いくつか工夫して実装する必要がありました。`}</p>
    <p><strong parentName="p">{`1. 氏名の選択結果を保持したまま、依頼内容選択の結果を「CallHistory」に書き出すこと`}</strong></p>
    <p>{`AppSheet が複数の選択結果を保持できるような仕組みになっておらず、依頼者選択画面から依頼内容一覧画面に遷移すると選択結果が消えるため、依頼者選択結果をデータとしてどこかに保持する必要がありました。`}</p>
    <p>{`→ 氏名の選択結果を格納する一行だけのテーブル「Client」を作成し、「CallHistory」への書き出し時に「Client」の一行目のデータを読むように設定しました。`}</p>
    <p><strong parentName="p">{`2. ボタンの配置と表示が変更できないこと`}</strong></p>
    <p>{`基本的にボタンに表示されているテキストは変更できず、ボタン位置も自由に変更できないため（画面の最上部か最下部のみ）、画面の設計には苦労しました。`}</p>
    <p>{`→ テキストに関しては、Settings → Views → Localization で「Save」を「次へ」に変更して対応しました。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/cf9134ef7a2f3bddcc02d70a3f62b665/8639e/note1223_012.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3aAH/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQABBQJf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGBAAAgMAAAAAAAAAAAAAAAAAARAgMUH/2gAIAQEAAT8hFmGr/9oADAMBAAIAAwAAABBLL//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABgQAQEBAQEAAAAAAAAAAAAAAAEAITER/9oACAEBAAE/ENB49l1hnFnuL//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note1223 012",
            "title": "note1223 012",
            "src": "/static/cf9134ef7a2f3bddcc02d70a3f62b665/e5166/note1223_012.jpg",
            "srcSet": ["/static/cf9134ef7a2f3bddcc02d70a3f62b665/f93b5/note1223_012.jpg 300w", "/static/cf9134ef7a2f3bddcc02d70a3f62b665/b4294/note1223_012.jpg 600w", "/static/cf9134ef7a2f3bddcc02d70a3f62b665/e5166/note1223_012.jpg 1200w", "/static/cf9134ef7a2f3bddcc02d70a3f62b665/d9c39/note1223_012.jpg 1800w", "/static/cf9134ef7a2f3bddcc02d70a3f62b665/df51d/note1223_012.jpg 2400w", "/static/cf9134ef7a2f3bddcc02d70a3f62b665/8639e/note1223_012.jpg 2430w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`ボタン位置に関してはどうにもならなかったため、なるべく導線がわかりやすいように画面上に説明を入れるようにしています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c1429ed57ff26548084ffe8d20c411ee/7e7db/note1223_014.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHr6qKmoT//xAAZEAACAwEAAAAAAAAAAAAAAAAAAQIDEyD/2gAIAQEAAQUCdaFXEzhx/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREv/aAAgBAwEBPwHTKz//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGH/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAECMiAxkf/aAAgBAQAGPwLSKxKrmH//xAAZEAABBQAAAAAAAAAAAAAAAACRABEgIUH/2gAIAQEAAT8hd0NawRN//9oADAMBAAIAAwAAABA43//EABURAQEAAAAAAAAAAAAAAAAAAABh/9oACAEDAQE/EKrP/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAECAQE/EITH/8QAGxABAAICAwAAAAAAAAAAAAAAAQARICExceH/2gAIAQEAAT8QWk48FdQC3jrHx2H/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "note1223 014",
            "title": "note1223 014",
            "src": "/static/c1429ed57ff26548084ffe8d20c411ee/e5166/note1223_014.jpg",
            "srcSet": ["/static/c1429ed57ff26548084ffe8d20c411ee/f93b5/note1223_014.jpg 300w", "/static/c1429ed57ff26548084ffe8d20c411ee/b4294/note1223_014.jpg 600w", "/static/c1429ed57ff26548084ffe8d20c411ee/e5166/note1223_014.jpg 1200w", "/static/c1429ed57ff26548084ffe8d20c411ee/7e7db/note1223_014.jpg 1474w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`ノーコードがゆえの制約はありますが、AppSheet での開発は簡単かつ迅速にできるため、小規模なアプリケーションであれば、おすすめの環境です。`}</p>
    <p>{`以上、呼び鈴アプリの開発についてお話しました。`}</p>
    <p>{`ここからは、コーポレート IT についてご紹介していきます。`}</p>
    <h1 id="about">コーポレート IT について</h1>
    <p>{`コーポレートデザイン部は、`}<strong parentName="p">{`「未来志向で社内の IT インフラを含めたオフィス環境をデザインすること」`}</strong>{` をミッションに業務しており、現在 2 つのチームがあります。`}</p>
    <p>{`一つはワークプレイスチームで、ペン一本からオフィス施工に関することまで、オフィス環境全般を担当しています。`}</p>
    <p>{`そして、もう一つがコーポレート IT チームで、全社で利用する SaaS の管理やオフィスのネットワークインフラ、社内アプリの開発等、全従業員が利用する IT ソリューション全般の企画・開発・運用を担当しています。`}</p>
    <h2>{`コーポレート IT の役割`}</h2>
    <p>{`コーポレート IT としてのミッションは、`}<strong parentName="p">{`「テクノロジーと創意工夫で従業員のパフォーマンスの最大化を図ること」`}</strong>{`です。`}</p>
    <p>{`そのために、単に上流・下流というフェーズ分担ではなく、システム導入だけでもない、全社の組織カルチャーづくりも見据えた全従業員が利用するコーポレート基盤をデザインし、そのデザインに基づいて IT ソリューションを実装、運用していきます。`}</p>
    <p>{`逆に、各部門のミッション達成、業務目的達成のための個別の IT ソリューションは、ツールゆえ各部門が主管です。コーポレート IT は IT プロフェッショナルとしてアドバイスしたり、システム設計や実装する形で、技術的実現を担うものとして各部門とコラボします。`}</p>
    <p>{`各部門の IT ソリューションに対する PDCA を各部門がオーナーシップを持つことで、利用時の要望や改善に対して、非常に早いサイクルで実施することができています。`}</p>
    <h1>{`業務内容紹介`}</h1>
    <p>{`コーポレート IT では、日々の従業員への IT サポート以外に、複数のプロジェクトが同時に走っています。以下に記載している業務はほんの一例です。`}</p>
    <ul>
      <li parentName="ul">{`端末管理アプリケーションおよびセキュリティ製品の企画/導入/運用`}</li>
      <li parentName="ul">{`オフィス移転でのネットワーク構築（ネットワークの設計、機器選定〜運用まで）`}</li>
      <li parentName="ul">{`社外来客用受付システムの改修/運用`}</li>
      <li parentName="ul">{`呼び鈴アプリの企画/開発/運用`}</li>
      <li parentName="ul">{`従業員向けの情報セキュリティ研修`}</li>
      <li parentName="ul">{`人事基幹システムの導入支援/各システムとの連携機能構築
etc`}</li>
    </ul>
    <p>{`また、メンバー自ら提案して企画化、予算を確保することもあり、アイデアに対して柔軟に対応しています。`}</p>
    <h1>{`業務を行う上で常に意識していること・大事にしていること`}</h1>
    <p>{`コーポレート IT として業務を行う上で大事にしている考え方が、3 つあります。従業員の生産性が向上する環境の構築に必要不可欠と考えており、これらをもとに意思決定しています。`}</p>
    <h2>{`1. システム選定/技術選定の考え方`}</h2>
    <p>{`システム選定/技術選定の原則としては、グローバルトップシェアで、かつ、北米急成長企業が選定しているものを未来志向で活用していくことを意識していますが、その技術やツールを導入して、得たい本来の目的が達成できるかどうかを、広い視点から判断して意思決定しています。`}</p>
    <h2>{`2. ユーザー IT サポートの考え方`}</h2>
    <p>{`老子が言っている『授人以魚 不如授人以漁(人に魚を授けることは、漁の仕方を教えるに及ばない)』ということを大切にしており、「わからない」という人に単に答えを「教える」ことをせず、組織の未来のために、組織全体の IT リテラシーを上げること、スムーズに自己解決できる環境を整えることに努めています。`}</p>
    <p>{`「ユーザー IT サポートが増え続け、サポート人件費が増大し、高コストな生産性の低いコーポレート IT チーム」とならないよう心がけています。`}</p>
    <h2>{`3. ルールを作らず、仕組み/仕掛けで解決する`}</h2>
    <p>{`ルールを覚えなくても、意識しなくとも、狙ったことが自然に進むよう、仕組み/仕掛けによって目的を成す方法を作ることを意識しています。`}</p>
    <p>{`例えば、高速道路のサービスエリアにある駐車場の逆走を禁止するために斜めに駐車位置ラインを引くといった、意識せずとも行動を一定方向に促す行動設計が望ましく、呼び鈴アプリもそういった仕掛けやプロセス設計の一環です。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`コーポレート IT は全従業員が利用する IT 基盤の維持・改善が求められるため、非常に重要な役割です。また、急拡大する組織の中においては、今までの仕組みが通用しなくなり、未来志向で新たな仕組みを設計し続けていく必要があり、常にコーポレート IT チームの活躍の余地がたくさん存在しています。`}</p>
    <p>{`そのため、未来志向で `}<a parentName="p" {...{
        "href": "https://www.medley.jp/team/culture.html"
      }}>{`#革新と改善を主導`}</a>{` できる方を絶賛募集しております。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/corporate-design.html"
      }}>{`https://www.medley.jp/jobs/corporate-design.html`}</a></p>
    <p>{`メドレーでは、医療分野の社会課題を IT にて解決するために日々邁進しております。様々な業種でスペシャリストを募集しています。興味がある方は是非ご連絡ください。医療という社会貢献性の高い領域へ、一緒に挑戦しませんか？`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      